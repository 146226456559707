<template>
  <div class="db-main">
    <v-row class="justify-center">
      <v-col cols="12" class="d-flex justify-center text-center pt-10">
        <v-img
          lazy-src="../assets/dashboard.png"
          width="200"
          max-width="500"
          src="../assets/dashboard.png"
        ></v-img>
      </v-col>
      <v-col cols="12" class="justify-center text-center">
        <h1
          cols="12"
          class="font-weight-black"
          style="font-size: 100px; margin-bottom: -30px !important; color:#07617b"
        >
          IMSO
        </h1>
        <h2 cols="12" class="font-weight-medium" style="color:#07617b">
          Information Management System of OPEnE
        </h2>
        <p class="px-5 pt-3" style="color: #616161">
          Welcome to the Information Management System of OPEnE. The platform
          intended to improve internal operations of the Oranization of People
          for Engagement and Enterprise. Please use the platform with accuracy
          and care. Please check with your immediate supervisors if you need
          help.
        </p>
      </v-col>
      <v-col cols="12" md="6" lg="4" class="d-flex justify-center">
        <v-btn block x-large dark color="#07617b" link to="/running-log/new-entry">
          Fill Running log
          <v-icon right dark> mdi-speedometer </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" lg="4" class="d-flex justify-center">
        <v-btn block x-large dark color="#07617b" link to="/employees/all">
          Find co-employees
          <v-icon right dark> mdi-account-group </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
};
</script>

<style>
.db-main {
  padding: 20px;
  margin-left: 50px;
  width: calc(100vw - 60px);
  min-height: calc(100vh - 200px);
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 960px) {
  .db-main {
    padding-right: 5px;
  }
}

@media only screen and (max-width: 600px) {
  .db-main {
    padding: 20px;
    margin-left: 0px;
    width: 100vw;
  }
}
</style>